/* need to make separate intro page and a nav bar */

#home {
  width: 100%;
  height: 100vh;
  margin: -8rem 0;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
}

#home .banner {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
#home .banner-text { width: 100%; }
#home .banner-text h1 {
  font: 90px/1.1em 'opensans-bold', sans-serif;
  color: #fff;
  letter-spacing: -2px;
  margin: 0 auto 18px auto;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, .8);
}
#home .banner-text h3 {
  font: 21px/2.2em 'librebaskerville-regular', serif;
  color: #b0b0b0;
  width: 90%;
  margin: 0 auto;
  text-shadow: 0px 3px 4px rgb(0, 0, 0);
}