footer {
  height: 8rem;
  background-color: transparent;
  width:100%;
  overflow: hidden;
  z-index: 5 !important;
}

footer a, footer a:visited { color: #FFF; }
footer a:hover, footer a:focus { color: #56d4fd; }

/* social links */
footer .social-links {
  margin: 2.5rem;
  font-size: 30px;
}

footer .social-links li{
  margin: 0 2rem;
}
