#resume {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
}

#resume a, #resume a:visited  { color: #11ABB0; }
#resume a:hover, #resume a:focus { color: #313131; }

#resume h1 {
  font: 18px/24px 'opensans-bold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#resume h1 span {
  border-bottom: 3px solid #11ABB0;
  padding-bottom: 6px;
}
#resume h3 {
  font: 25px/30px 'opensans-bold', sans-serif;
}

#resume .header-col { 
  padding: 0.5rem 2rem 0 4rem; 
  flex: 2;
}
#resume .main-col { 
  padding: 0.5rem 2rem 0 4rem; 
  padding-right: 10%; 
  flex: 8;
}

.education, .work, .skill {
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #E8E8E8;
}
#resume .info {
  font: 16px/24px 'librebaskerville-italic', serif;
  color: #6E7881;
  margin-bottom: 18px;
  margin-top: 9px;
}
#resume .info span {
  margin-right: 5px;
  margin-left: 5px;
}
#resume .date {
  font: 15px/24px 'opensans-regular', sans-serif;
  margin-top: 6px;
}

@media only screen and (min-width: 500px) {
  .work, .education, .skill{
    flex-direction: row;
  }
}