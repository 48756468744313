#contact {
  background: #191919;
  padding-top: 96px;
  color: #636363;
  overflow: hidden;
}
#contact .section-head { 
  width: 80%;
  margin: 0 auto 4rem auto;
}

#contact h1 {
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #EBEEEE;
  margin-bottom: 6px;
}
#contact h1 span { display: none; }
#contact h1:before {
  font-family: 'FontAwesome';
  content: "\f0e0";
  padding-right: 10px;
  font-size: 72px;
  line-height: 72px;
  text-align: left;
  float: left;
  color: #ebeeee;
}

#contact .section-body{
  display: flex;
  flex-direction: column-reverse;
  width: 80%;
  margin: 0 auto;
}

#contact h4 {
  font: 16px/24px 'opensans-bold', sans-serif;
  color: #EBEEEE;
  margin-bottom: 6px;
}
#contact p.lead {
  font: 18px/36px 'opensans-light', sans-serif;
  padding-right: 3%;
  padding-left: 5%;
}

/* contact form */
#contact label {
  font: 15px/24px 'opensans-bold', sans-serif;
  margin: 12px 0;
  color: #EBEEEE;
  float: left;
  width: 26%;
}
#contact input,
#contact textarea,
#contact select {
  padding: 18px 20px;
  color: #eee;
  background: #373233;
  margin-bottom: 42px;
  border: 0;
  outline: none;
  font-size: 15px;
  line-height: 24px;
  width: 65%;
}
#contact input:focus,
#contact textarea:focus,
#contact select:focus {
 color: #fff;
 background-color: #11ABB0;
}
#contact button.submit {
 text-transform: uppercase;
 letter-spacing: 3px;
 color:#fff;
  background: #0D0D0D;
 border: none;
  cursor: pointer;
  height: auto;
  display: inline-block;
 border-radius: 3px;
  margin-left: 26%;
}
#contact button.submit:hover {
 color: #0D0D0D;
 background: #fff;
}
#contact span.required {
 color: #11ABB0;
 font-size: 13px;
}
#message-warning, #message-success {
  display: none;
 background: #0F0F0F;
 padding: 24px 24px;
 margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}
#message-warning { color: #D72828; }
#message-success { color: #11ABB0; }

#message-warning i,
#message-success i {
  margin-right: 10px;
}
#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

@media only screen and (min-width: 700px) {
  #contact .section-body{
    flex-direction: row;
  }
}